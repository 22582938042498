import { Button, Flex, IconEye, IconEyeHidden, IconQuestionMarkCircle, SimpleInput } from "@caisy/league";
import Link from "next/link";
import React from "react";
import { signWithEmailAndPassword } from "../../../services/firebase";
import { SLoginForm } from "./Styles/SLoginForm";
import { useI18nLogin } from "src/provider/i18n/I18nProvider";
import { useRouter } from "next/router";
import { SLoginFormForgotPassword } from "./Styles/SLoginFormForgotPassword";
import { SLoginFormPasswordIcon } from "./Styles/SLoginFormPasswordIcon";
import { MaintenanceWarningLogin } from "../maintenance-warning/maintenance-warning-login/MaintenanceWarningLogin";

export const LoginForm: React.FC<{ setError: React.Dispatch<React.SetStateAction<boolean>> }> = ({ setError }) => {
  const [password, setPassword] = React.useState<string>("");
  const [email, setEmail] = React.useState<string | undefined>("");
  const [emailError, setEmailError] = React.useState<any>(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState<any>(null);
  const [loggingIn, setLoggingIn] = React.useState(false);
  const router = useRouter();
  const labels = useI18nLogin();

  const onSubmit = async () => {
    if (email && password) {
      setLoggingIn(true);
      try {
        await signWithEmailAndPassword(`${email}`.trim(), password, router);
        setEmailError(null);
        setPasswordError(null);
        setError(false);
      } catch (e) {
        setError(true);
        setLoggingIn(false);
      }
    } else {
      !email && setEmailError(labels?.welcome_emailIsRequired ?? "Email is required");
      !password && email && setPasswordError(labels?.welcome_passwordIsRequired ?? "Password is required");
    }
  };

  const updateEmail = (v) => {
    if (v !== email) {
      setEmail(v);
      setEmailError(undefined);
    }
  };

  const updatePassword = (v) => {
    if (v !== password) {
      setPassword(v);
      setPasswordError(undefined);
    }
  };

  return (
    <SLoginForm
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <MaintenanceWarningLogin />

      <SimpleInput
        state={emailError ? "error" : "default"}
        type="email"
        autoComplete="email"
        id="email-input-id"
        label={emailError ? emailError : labels?.welcome_workEmailLabel ?? "Work Email"}
        placeholder={labels?.welcome_workEmailPlaceholder ?? "Enter your work email address"}
        value={email}
        onChange={(e) => updateEmail(e.target.value)}
      />
      <SimpleInput
        state={passwordError && !emailError ? "error" : "default"}
        type={showPassword ? "text" : "password"}
        rightIcon={
          <SLoginFormPasswordIcon
            onClick={(e) => {
              e.stopPropagation();
              password && setShowPassword((prevState) => !prevState);
            }}
          >
            {showPassword ? <IconEyeHidden size={20} /> : <IconEye size={20} />}
          </SLoginFormPasswordIcon>
        }
        autoComplete="current-password"
        id="password-input-id"
        label={passwordError && !emailError ? passwordError : labels?.welcome_passwordLabel ?? "Password"}
        placeholder={labels?.welcome_passwordPlaceholder ?? "Enter your password"}
        value={password}
        onChange={(e) => {
          updatePassword(e.target.value);
        }}
      />
      <SLoginFormForgotPassword>
        <IconQuestionMarkCircle />
        <Link href="/reset-password-request" passHref={true} shallow={true}>
          {labels?.welcome_forgotPassword ?? "Forgot Password?"}
        </Link>
      </SLoginFormForgotPassword>
      <Button disabled={loggingIn} style={{ display: "block", width: "100%" }} type="primary">
        <Flex alignItems="center" gap="8px" justify="center">
          {loggingIn ? (
            <img style={{ width: 20, height: 20 }} src={`/app/static-assets/spinner.gif`} />
          ) : (
            labels?.welcome_signIn ?? "sign in"
          )}
        </Flex>
      </Button>
    </SLoginForm>
  );
};
