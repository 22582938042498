import { CSSProgressiveCaption01Semibold } from "@caisy/league";
import styled, { css } from "styled-components";
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/styles/mediaquerys";

const Default = css`
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--text-04);
  padding: 4px;
  ${CSSProgressiveCaption01Semibold};

  svg {
    width: 16px;
    height: 16px;
  }

  a {
    user-select: none;
    color: var(--text-04);
    text-decoration: none;
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SLoginFormForgotPassword = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
