import { Button, SimpleInput } from "@caisy/league/src/index";
import gql from "graphql-tag";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { client } from "../../../utils/client";
import { SLoginButtons } from "../../page/login-and-reset-request/login/Styles/SLoginButtons";
import { SLoginError } from "../../page/login-and-reset-request/login/Styles/SLoginError";
import { SLoginForm } from "../LoginForm/Styles/SLoginForm";
import { useI18nLogin } from "src/provider/i18n/I18nProvider";
import { isValidEmail } from "../../page/register/utils";

let triggerTimeout = undefined;
let blocked = false;

export const ResetPasswordRequestForm: React.FC<{
  setIsSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  isSubmit: boolean;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  email: string;
}> = ({ setIsSubmit, isSubmit, email, setEmail }) => {
  const [error, setError] = React.useState<string | undefined>(undefined);
  const router = useRouter();
  const labels = useI18nLogin();

  useEffect(() => {
    return () => {
      if (triggerTimeout) {
        clearTimeout(triggerTimeout);
      }
    };
  }, []);
  const onSubmit = async () => {
    if (email) {
      setError(undefined);
      if (!isValidEmail(email)) {
        setError(labels.reset_password_invalidEmail || "Invalid email");
        return;
      }
      try {
        if (!blocked) {
          blocked = true;
          triggerTimeout = setTimeout(() => {
            blocked = false;
          }, 800);
          const res = await client.mutate({
            mutation: gql`
              mutation sendResetPasswordEmail($email: String!) {
                sendResetPasswordEmail(email: $email)
              }
            `,
            variables: {
              email,
            },
          });
          if (res?.errors) {
            if (res?.errors?.[0]?.message) {
              setError(`${res?.errors?.[0]?.message}`.split("=")?.[2] || `${res?.errors?.[0]?.message}`);
              return;
            }
            setError(`Error: ${res?.errors}`);
            return;
          }
          setError(undefined);
          setIsSubmit(true);
        }
      } catch (e) {
        setError(e.message ? `${e.message}`.split("=")?.[2] || `${e.message}` : undefined);
      }
    } else {
      setError(labels?.welcome_required ?? "Required");
    }
  };

  const updateEmail = (v) => {
    if (v !== email) {
      setEmail(v);
      setError(undefined);
    }
  };

  let removeError;
  React.useEffect(() => {
    if (isSubmit && error) {
      removeError = setTimeout(() => {
        setError(undefined);
      }, 12000);
    }

    return () => {
      clearTimeout(removeError);
    };
  }, [error, isSubmit]);

  return (
    <SLoginForm
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      {isSubmit ? (
        <>
          {error && <SLoginError>{error}</SLoginError>}
          <SLoginButtons>
            <Button
              type="secondary"
              onClick={(e) => {
                e.preventDefault();
                onSubmit();
              }}
              disabled={!!error}
            >
              {labels?.reset_password_resendEmail ?? "Resend reset email"}
            </Button>
            <Button
              type="secondary"
              onClick={(e) => {
                e.preventDefault();
                setIsSubmit(false);
              }}
            >
              {labels?.reset_password_reenterEmail ?? "Re-enter email address"}
            </Button>
          </SLoginButtons>
        </>
      ) : (
        <>
          <SimpleInput
            state={error ? "error" : "default"}
            type="text"
            // errors={error && [error]}
            autoComplete="email"
            id="email-input-id"
            label={error ? error : labels?.welcome_workEmailLabel ?? "Work Email"}
            placeholder={labels?.welcome_workEmailPlaceholder ?? "Enter your work email address"}
            value={email}
            onChange={(e) => updateEmail(e.target.value)}
          />
          <SLoginButtons>
            <Button style={{ display: "block", width: "100%" }} type="primary">
              {labels?.reset_password_sendLink ?? "Send reset link"}
            </Button>
            <Button
              style={{ display: "block", width: "100%" }}
              type="secondary"
              onClick={(e) => {
                e.preventDefault();
                router.back();
              }}
            >
              {labels?.reset_password_back ?? "back"}
            </Button>
          </SLoginButtons>
        </>
      )}
    </SLoginForm>
  );
};
