import { CSSProgressiveCaption01Semibold } from "@caisy/league";
import styled, { css } from "styled-components";
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "src/constants/styles/mediaquerys";

const Bronze = css`
  display: flex;
  align-items: center;
  gap: 9px;
  ${CSSProgressiveCaption01Semibold};
  color: var(--text-04);
  margin-left: auto;

  a {
    text-decoration: none;
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SLoginHeaderWrapper = styled.div`
  ${Bronze};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
