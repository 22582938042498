import styled from "styled-components";

export const SMaintenanceWarningLogin = styled.div`
  padding: 16px 24px 16px 16px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  border-radius: 8px;
  background-color: var(--ui-supportive-03);
`;
