import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { useI18nLogin } from "../../../provider/i18n/I18nProvider";
import { LoginHeader } from "../../molecule/login-header/LoginHeader";
import Login from "./login/Login";
import { SLogin } from "./login/Styles/SLogin";
import { SLoginImage } from "./login/Styles/SLoginImage";
import { SLoginWrapper } from "./login/Styles/SLoginWrapper";
import ResetPasswordRequest from "./reset-password-request";

const loginImages = (
  <>
    <img src={`/app/static-assets/login/login_bg.svg`} alt="" />
    <img src={`/app/static-assets/login/login_layer_0.svg`} alt="" />
    <img src={`/app/static-assets/login/login_layer_1.svg`} alt="" />
    <img src={`/app/static-assets/login/login_layer_2.svg`} alt="" />
  </>
);

const LoginAndResetRequestContainer: React.FC = () => {
  const router = useRouter();
  const [isFirstRendered, setIsFirstRendered] = React.useState(false);
  const labels = useI18nLogin();

  useEffect(() => {
    setIsFirstRendered(true);
  }, []);

  const routeChildren = () => {
    switch (router?.pathname) {
      case "/login":
        return <Login />;
      case "/reset-password-request":
        return <ResetPasswordRequest />;
    }
  };

  return (
    <>
      {/* <CookieYesScript /> */}
      <SLogin style={{ display: isFirstRendered ? "flex" : "none" }}>
        <SLoginWrapper>
          <LoginHeader />
          {routeChildren()}
        </SLoginWrapper>
        <SLoginImage color={router?.pathname === "/login" ? "blue" : "orange"}>
          {router?.pathname === "/login" ? loginImages : <img src={labels?.reset_password_image?.src} alt="" />}
        </SLoginImage>
      </SLogin>
    </>
  );
};

export default LoginAndResetRequestContainer;
