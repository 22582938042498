import { CSSProgressiveCaption01 } from "@caisy/league";
import styled, { css } from "styled-components";
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../../constants/styles/mediaquerys";

const Default = css`
  margin-top: 24px;
  text-align: center;
  ${CSSProgressiveCaption01};
  color: var(--text-04);

  a {
    text-decoration: none;
    color: var(--text-09);
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SLoginSignUpMessage = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
