import styled, { css } from "styled-components";
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../../constants/styles/mediaquerys";

const Default = css`
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
`;

const Silver = css`
  padding: 0;
  left: 8.12%;
  width: 86.8%;
  transform: translateX(0);
`;

const Gold = css`
  left: 23.31%;
  width: 57.66%;
`;

const Platinum = css``;

const Diamond = css`
  left: 23.14%;
  width: 59%;
`;

export const SLoginNotification = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
