import styled, { css } from "styled-components";
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "src/constants/styles/mediaquerys";

const Bronze = css`
  padding: 20px 20px 20px 32px;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`;

const Silver = css``;

const Gold = css`
  padding: 20px 24px 20px 52px;
`;

const Platinum = css``;

const Diamond = css`
  padding: 20px 24px 20px 76px;
`;

export const SLoginHeader = styled.div`
  ${Bronze};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
