import { IconCross, IconCheckmarkCircle, NotificationSnackbar, IconCrossCircle } from "@caisy/league";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useI18nLogin } from "src/provider/i18n/I18nProvider";
import { checkFirebaseLogin } from "../../../../services/firebase";
import { LoginForm } from "../../../organism/LoginForm";
import { SLoginContent } from "./Styles/SLoginContent";
import { SLoginNotification } from "./Styles/SLoginNotification";
import { SLoginNotificationIcon } from "./Styles/SLoginNotificationIcon";
import { SLoginNotificationIconClose } from "./Styles/SLoginNotificationIconClose";
import { SLoginSignUpMessage } from "./Styles/SLoginSignUpMessage";
import { SLoginTitle } from "./Styles/SLoginTitle";

export const Login: React.FC = () => {
  const router = useRouter();
  const [exitNotification, setExitNotification] = useState(true);
  const [error, setError] = React.useState(false);

  const labels = useI18nLogin();
  const { success } = router.query;

  useEffect(() => {
    if (success) {
      setExitNotification(false);
      router.push("/login", undefined, { shallow: true });
    }
  }, [success]);

  useEffect(() => {
    checkFirebaseLogin({ router });
  }, []);

  return (
    <>
      <SLoginContent>
        <SLoginTitle> {labels?.welcome_titleMessage ?? "Welcome Back"}</SLoginTitle>
        <LoginForm setError={setError} />
        <SLoginSignUpMessage
          dangerouslySetInnerHTML={{
            __html:
              labels?.welcome_signUpMessage ??
              'By signing up, you agree to our <a target="_blank" href="/legal">Terms of Service</a> and acknowledge our <a target="_blank" href="/privacy">Privacy Policy</a>.',
          }}
        />
      </SLoginContent>
      {(!exitNotification || error) && (
        <SLoginNotification>
          <NotificationSnackbar
            styleOverwrite={{
              width: "100%",
              margin: 0,
              backgroundColor: error ? "var(--ui-supportive-02)" : "",
            }}
            exit={false}
            content={
              error
                ? labels?.welcome_wrongEmailOrPassword ?? "Wrong email or password. Please try again"
                : labels?.welcome_notificationMessage ?? "New password has been saved. Please sign in"
            }
            icon={
              <SLoginNotificationIcon>
                {error ? <IconCrossCircle size={16} /> : <IconCheckmarkCircle size={16} solid />}
              </SLoginNotificationIcon>
            }
            // @ts-ignore
            action={
              <SLoginNotificationIconClose
                onClick={() => {
                  error ? setError(false) : setExitNotification(true);
                }}
              >
                <IconCross size={20} />
              </SLoginNotificationIconClose>
            }
          />
        </SLoginNotification>
      )}
    </>
  );
};

export default Login;
