import { IconPasswordKey, IconEmail } from "@caisy/league";
import React from "react";
import { ResetPasswordRequestForm } from "../../../organism/reset-password-request-from";
import { useI18nLogin } from "src/provider/i18n/I18nProvider";
import { SLoginContent } from "../login/Styles/SLoginContent";
import { SLoginIconWrapper } from "../login/Styles/SLoginIconWrapper";
import { SLoginMessage } from "../login/Styles/SLoginMessage";
import { SLoginTitle } from "../login/Styles/SLoginTitle";

const ResetPasswordRequest: React.FC = () => {
  const labels = useI18nLogin();
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [email, setEmail] = React.useState("");

  return (
    <SLoginContent>
      <SLoginIconWrapper color="orange">
        {isSubmit ? <IconEmail size={40} /> : <IconPasswordKey size={40} />}
      </SLoginIconWrapper>
      <SLoginTitle
        styleOverwrite={{
          marginBottom: isSubmit ? "16px" : "24px",
        }}
      >
        {isSubmit
          ? labels?.reset_password_checkEmail ?? "Check your email"
          : labels?.reset_password_title ?? "Reset password"}
      </SLoginTitle>
      {isSubmit && (
        <SLoginMessage>
          <p>{labels?.reset_password_linkSent ?? "We've sent you a link to"}</p>
          <span>{email}</span>
          <p>{labels?.reset_password_clickLink ?? "Please click the link to reset your password"}</p>
        </SLoginMessage>
      )}
      <ResetPasswordRequestForm isSubmit={isSubmit} setIsSubmit={setIsSubmit} email={email} setEmail={setEmail} />
    </SLoginContent>
  );
};

export default ResetPasswordRequest;
