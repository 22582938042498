import styled, { css } from "styled-components";
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../../constants/styles/mediaquerys";

type IColors = "blue" | "orange" | "lime" | "violet";
const colors = {
  blue: "linear-gradient(102.45deg, #008CFF 16.49%, #0047FF 89.22%)",
  orange: "linear-gradient(102.45deg, #FF8A00 16.49%, #EF7301 89.22%)",
  lime: "linear-gradient(102.45deg, #9EFF00 16.49%, #01EF6F 89.22%)",
  violet: "linear-gradient(102.45deg, #6100FF 16.49%, #8000FF 89.22%)",
};
const Default = css<{ color: IColors }>`
  height: 100%;
  display: none;
`;

const Silver = css`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: ${({ color }) => colors[color]};
  width: 48.69%;
  position: relative;
  img {
    position: absolute;
    left: 0%;
    width: 100%;
    object-fit: contain;
  }

  img:nth-child(2) {
    transform: rotate3d(0.5, -0.866, 0, 25deg) rotate(2deg);
    transition: transform 0.4s ease;
  }
  img:nth-child(3) {
    transform: rotate3d(0.5, -0.866, 0, 15deg) rotate(2deg);
    transition: transform 0.4s ease;
  }
  img:nth-child(4) {
    transform: rotate3d(0.5, -0.866, 0, 25deg) rotate(2deg);
    transition: transform 0.4s ease;
  }

  &:hover {
    img:nth-child(2) {
      transform: rotate3d(0.5, -0.866, 0, 15deg) rotate(1deg);
    }
    img:nth-child(3) {
      transform: rotate3d(0, 0, 0, 0) rotate(0deg);
    }
    img:nth-child(4) {
      transform: rotate3d(0.5, -0.866, 0, 15deg) rotate(1deg);
    }
  }
`;

const Gold = css`
  width: 49%;
`;

const Platinum = css``;

const Diamond = css`
  width: 49.37%;
`;

export const SLoginImage = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
