import { FC } from "react";
import { SMaintenanceWarningLogin } from "./styles/SMaintenanceWarningLogin";
import { SMaintenanceWarningLoginIcon } from "./styles/SMaintenanceWarningLoginIcon";
import { SMaintenanceWarningLoginContent } from "./styles/SMaintenanceWarningLoginContent";
import { SMaintenanceWarningLoginTitle } from "./styles/SMaintenanceWarningLoginTitle";
import { SMaintenanceWarningLoginDescription } from "./styles/SMaintenanceWarningLoginDescription";
import MaintenanceWarningLoginIcon from "./MaintenanceWarningLoginIcon";
import { useMaintenanceWarning } from "../useMaintenanceWarning";

export const MaintenanceWarningLogin: FC = () => {
  const { title, description, visible } = useMaintenanceWarning();

  if (!visible) return null;

  return (
    <SMaintenanceWarningLogin>
      <SMaintenanceWarningLoginIcon>
        <MaintenanceWarningLoginIcon />
      </SMaintenanceWarningLoginIcon>
      <SMaintenanceWarningLoginContent>
        <SMaintenanceWarningLoginTitle>{title}</SMaintenanceWarningLoginTitle>

        <SMaintenanceWarningLoginDescription>{description}</SMaintenanceWarningLoginDescription>
      </SMaintenanceWarningLoginContent>
    </SMaintenanceWarningLogin>
  );
};
