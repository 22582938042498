import React from "react";

function MaintenanceWarningLoginIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        stroke="#ED3E3E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10.498 6.666a.208.208 0 10.002.417.208.208 0 00-.002-.417"
      ></path>
      <path
        fillRule="evenodd"
        stroke="#ED3E3E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10.5 17.5v0A7.5 7.5 0 013 10v0a7.5 7.5 0 017.5-7.5v0A7.5 7.5 0 0118 10v0a7.5 7.5 0 01-7.5 7.5z"
        clipRule="evenodd"
      ></path>
      <path stroke="#ED3E3E" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M10.499 10v4.167"></path>
    </svg>
  );
}

export default MaintenanceWarningLoginIcon;
