import { CSSProgressiveBody03Medium } from "@caisy/league";
import styled from "styled-components";

export const SMaintenanceWarningLoginDescription = styled.p`
  ${CSSProgressiveBody03Medium};
  color: var(--text-04);

  strong {
    font-weight: bold;
  }
`;
